/* eslint-disable @atlaskit/design-system/ensure-design-token-usage */
import React from "react";

import { ToastContainer } from "@commonComponents";
import { datadogRum } from "@datadog/browser-rum";
import FavIconUrl from "@images/favicon.ico";
import loadable from "@loadable/component";
import * as Sentry from "@sentry/react";
import axios from "axios";
import Highcharts from "highcharts";
import { createBrowserHistory } from "history";
import { asyncWithLDProvider } from "launchdarkly-react-client-sdk";
import { createRoot } from "react-dom/client";
import Favicon from "react-favicon";
import "./index.css";
import "react-circular-progressbar/dist/styles.css";
import "reactflow/dist/style.css";
import { Provider } from "react-redux";
import userflow from "userflow.js";

const App = loadable(() => import("./App.tsx"));

import store from "./store";
const history = createBrowserHistory();

if (window.location.hostname !== "localhost") {
  Sentry.init({
    dsn: "https://d638b6e88bf044eebc803b4a9851d1fa@o524965.ingest.sentry.io/5454563",
    integrations: [
      Sentry.reactRouterV5BrowserTracingIntegration({
        history,
      }),
      Sentry.captureConsoleIntegration({
        levels: ["error"],
      }),
      Sentry.sessionTimingIntegration(),
    ],
    tracesSampleRate: 1.0,
    normalizeDepth: 15,
  });

  /* eslint-disable no-undef */
  // Enable User flow
  if (USERFLOW_TOKEN) {
    userflow.init(USERFLOW_TOKEN);
    userflow.setResourceCenterLauncherHidden(true);
  }

  // Enable Data Dog
  if (DATA_DOG_CLIENT_TOKEN && DATA_DOG_APPLICATION_ID) {
    let env = window.location.hostname.split(".")[0];

    datadogRum.init({
      env,
      applicationId: DATA_DOG_APPLICATION_ID,
      clientToken: DATA_DOG_CLIENT_TOKEN,
      site: "datadoghq.com",
      service: "command-center-ui",
      sessionSampleRate: 100,
      sessionReplaySampleRate: 100,
      trackUserInteractions: true,
      trackResources: true,
      trackLongTasks: true,
      defaultPrivacyLevel: "allow",
      allowedTracingUrls: [/https:\/\/.*\.leap.lucernahealth\.com/],
    });
  }
  /* eslint-enable no-undef */
}

// Import font awesome kit - we do have local version of FA but this will always ensure we have the latest
(function () {
  var wa = document.createElement("script");
  wa.type = "text/javascript";
  wa.src = "https://kit.fontawesome.com/0870df4268.js";
  wa.crossOrigin = "anonymous";
  var s = document.getElementsByTagName("script")[0];
  s.parentNode.insertBefore(wa, s);
})();

const ENV_URL = false;

axios.defaults.baseURL = `${
  // eslint-disable-next-line no-undef
  ENV_URL ? ENV_URL : window.location.origin
}/api/v1/`;

Highcharts.setOptions({
  lang: {
    thousandsSep: ",",
  },
  chart: {
    style: {
      fontFamily: '"Manrope", sans-serif',
    },
  },
});

function AppRoot() {
  return (
    <Provider store={store}>
      <ToastContainer />
      <App />
    </Provider>
  );
}

const container = document.getElementById("app-root");
const root = createRoot(container);

// eslint-disable-next-line no-undef
if (LAUNCH_DARKLY_CLIENT_ID) {
  (async () => {
    const LDProvider = await asyncWithLDProvider({
      // eslint-disable-next-line no-undef
      clientSideID: LAUNCH_DARKLY_CLIENT_ID,
      options: {
        streaming: false,
        logger: {
          debug: () => {},
          info: () => {},
          // eslint-disable-next-line no-restricted-syntax
          warn: (...args) => console.warn(...args),
          error: (...args) => console.error(...args),
        },
      },
    });

    root.render(
      <LDProvider>
        <Favicon url={FavIconUrl} />
        <AppRoot />
      </LDProvider>,
    );
  })();
} else {
  root.render(
    <>
      <Favicon url={FavIconUrl} />
      <AppRoot />
    </>,
  );
}
